<template>
  <div v-if="text">
    <div @click="toggle()" class="cursor-pointer">
      <div class="d-inline">
        {{ text }}
      </div>
      <strong v-if="textLength > length" class="more--text">
        <span class="cursor-pointer" v-show="showFullText">
          Less<v-icon>mdi-chevron-up</v-icon></span
        >
        <span class="cursor-pointer" v-show="!showFullText"
          >... More<v-icon>mdi-chevron-down</v-icon></span
        >
      </strong>
    </div>
  </div>
</template>

<script>
import { truncate } from '../utils/string';
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    length: {
      type: Number,
      default: 100
    },
    showMore: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showFullText: false
    };
  },
  methods: {
    toggle() {
      this.showFullText = !this.showFullText;
    }
  },
  computed: {
    textLength() {
      return String(this.value || '').length;
    },
    text() {
      if (this.showFullText) {
        return this.value;
      }
      return truncate(this.value || '', this.length, true);
    }
  }
};
</script>

<style>
.more--text {
  color: gray;
}
</style>
